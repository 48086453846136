import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import logo from "./../../logo-white.png";



function Footer() {
    const [loading, setLoading] = useState(false)
    const Icon = ({ iconName, style }) => {
        return <i className={`ms-Icon ms-Icon--${iconName}`} aria-hidden="true" style={style}></i>;
    }

    useEffect(() => {
        setLoading(true)

        // cleanup function
        return () => {
        }
    }, [])

    return (
        <footer>
            <div className='__frame' data-width='xlarge'>
                <div className='footer-nav d-flex align-items-center justify-content-between flex-wrap'>
                    <figure>
                        <img alt={process.env.REACT_APP_NAME + ` logo`} src={logo} />
                    </figure>

                    <ul className='d-flex flex-column align-items-left'>
                        <li><a href="/#about">About Us</a></li>
                        <li><Link to="/team">Our Team</Link></li>
                        <li><Link to="/portfolio">Portfolio</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                </div>

                <div className='text-center copyright d-flex align-items-center justify-content-between flex-wrap'>
                    <p>© {new Date().getFullYear()} | Herakles Investment Partners, LLP. All Rights Reserved.</p>
                    <Link to='/legal' className='flex-grow-1'>Legal Information</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer