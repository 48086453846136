import React, { useState, useEffect } from 'react'
import { Routes, Route, Link } from "react-router-dom";
import MyErrorBoundary from "./routes/components/ErrorBoundary"
import Layout from './routes/components/Layout'
import Loading from './routes/components/Loading'
import logo from './logo.svg';
import './App.css';
import 'animate.css';

const Home = React.lazy(() => import('./routes/Home'));
const Portfolio = React.lazy(() => import('./routes/Portfolio'));
const Team = React.lazy(() => import('./routes/Team'));
const News = React.lazy(() => import('./routes/News'));
const Approach = React.lazy(() => import('./routes/Approach'));
const Contact = React.lazy(() => import('./routes/Contact'));
const Legal = React.lazy(() => import('./routes/Legal'));

function App() {
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
  }, [])

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) return <Loading />

  return (
    <MyErrorBoundary>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path='/' element={<Layout header={true} footer={true}><Home /></Layout>} />
          <Route path='/news' element={<Layout header={true} footer={true}><News /></Layout>} />
          <Route path='contact' element={<Layout header={true} footer={false}><Contact /></Layout>} />
          <Route path='team' element={<Layout header={true} footer={false}><Team /></Layout>} />
          <Route path='approach' element={<Layout header={true} footer={false}><Approach /></Layout>} />
          <Route path='legal' element={<Layout header={true} footer={false}><Legal /></Layout>} />
          <Route path='portfolio' element={<Layout header={true} footer={false}><Portfolio /></Layout>} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </React.Suspense>
    </MyErrorBoundary>
  );
}

const NoMatch = () => {
  return (
    <>
      <div className='__frame' data-width='medium'>
        <div className='notfound'>
          <h2>
            <i className={`ms-Icon ms-Icon--PlugDisconnected`} aria-hidden='true'></i>
            404!
          </h2>
        </div>
      </div>
    </>
  )
}

export default App;
