import { NavLink } from 'react-router-dom'
import logo from './../../logo.png'

const Menu = [
    {
        name: 'About Us',
        detail: 'We believe in value creation through operational improvement and accelerated growth.',
        url: '/?goto=about',
    },
    {
        name: 'Portfolio',
        detail: 'We enhance the value of middle-market businesses by bringing world-class operational, strategic, and financial resources to its portfolio companies.',
        url: '/portfolio',
    },
    {
        name: 'Team',
        detail: `Herakles investment process combines the disciplines of investing and operational improvement.`,
        url: '/team',
    },
    {
        name: 'Contact',
        detail: 'Connect with us.',
        url: '/contact',
    },
    {
        name: 'News',
        detail: 'Latest news',
        url: '/news',
    }
]

function Header() {
    const handleMenu = () => document.querySelector('header nav').classList.toggle('show')

    return (
        <>
            <header>
                <div className='__frame' data-width='xlarge'>
                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <figure onClick={() => { window.location.href = '/' }} >
                            <img alt={process.env.REACT_APP_APP_NAME} src={logo} />
                        </figure>

                        <ul className='pc-nav d-flex flex-row justify-content-between align-items-center'>
                            {
                                Menu.map((item, i) => (
                                    <li key={i}>
                                        {
                                            (i === 0) ? (
                                                <div className='nav-btn' style={{ cursor: 'pointer' }} onClick={() => {
                                                    //document.querySelector('header nav').classList.toggle('show')
                                                    window.location.href = '/?goto=about'
                                                    document.body.scrollTo(0, document.querySelector('#about').offsetTop)

                                                }}>
                                                    <span>{item.name}</span>
                                                </div>
                                            ) : (
                                                <NavLink className='nav-btn' to={item.url}>
                                                    <span>{item.name}</span>
                                                </NavLink>
                                            )
                                        }
                                    </li>
                                ))
                            }
                        </ul>

                        <div className='menu' onClick={() => handleMenu()}>
                            <i className='material-symbols-rounded'>menu</i>
                        </div>
                    </div>
                </div>

                <nav>
                    <div className='__frame' data-width='xlarge'>
                        <ul>
                            {
                                Menu.map((item, i) => (
                                    <li key={i}>
                                        {
                                            (i === 0) ? (
                                                <div className='nav-btn' style={{ cursor: 'pointer' }} onClick={() => {
                                                    //document.querySelector('header nav').classList.toggle('show')
                                                    window.location.href = '/?goto=about'
                                                    document.body.scrollTo(0, document.querySelector('#about').offsetTop)

                                                }}>
                                                    <span>{item.name}</span>
                                                    <p>{item.detail}</p>
                                                </div>
                                            ) : (
                                                <NavLink className='nav-btn' to={item.url}>
                                                    <span>{item.name}</span>
                                                    <p>{item.detail}</p>
                                                </NavLink>
                                            )
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header;