import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Header from './Header'
import Footer from './Footer'
import logoMotion from './../../video/logoMotion.mp4'
import motionGif from './../../images/motion.gif'

function Layout({ children, header, footer }) {
    const [motion, setMotion] = useState(!localStorage.getItem('motion'))

    const motionEnded = () => {
        document.querySelector('.motion').style.display = 'none !important'
        setMotion(false)
        localStorage.setItem('motion', true)
    }

    window.setInterval(() => {
        if (motion) motionEnded()
    }, 10000)

    return (
        <>
            {motion && (
                <div className='motion'>
                    <img src={motionGif} alt='logo motion' />
                </div>
            )}

            {header && <Header />}
            <main className='ms-motion-fadeIn'>
                {children}
            </main>
            {footer && <Footer />}
        </>
    )
}

// Specifies the default values for props:
Layout.defaultProps = {
    header: true,
    footer: true
};

Layout.propTypes = {
    children: PropTypes.object.isRequired,
    header: PropTypes.bool
};

export default Layout